<template>
    <div class="mt-3">

        <h2>
            <i class="bi bi-person-lines-fill"></i>
            Liste des utilisateurs
        </h2>

        <div class="my-3">
          <button type="button" class="btn btn-primary btn-sm" tag="button" @click.prevent="createUser()">
              <i class="bi bi-pencil-square"></i>
              New
          </button>
        </div>

        <div class="my-3">
            <div class="form-check form-check-inline">
                <label class="form-check-label" for="filters">Filters on :</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="state.checkedUsers" v-on:change="showSelectedUsers()" class="form-check-input" type="checkbox" :value="parseInt(0)">
                <label class="form-check-label" for="news">News</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="state.checkedUsers" v-on:change="showSelectedUsers()" class="form-check-input" type="checkbox" :value="parseInt(1)">
                <label class="form-check-label" for="users">Users</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="state.checkedUsers" v-on:change="showSelectedUsers()" class="form-check-input" type="checkbox" :value="parseInt(2)">
                <label class="form-check-label" for="masters">Masters</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="state.checkedUsers" v-on:change="showSelectedUsers()" class="form-check-input" type="checkbox" :value="parseInt(3)">
                <label class="form-check-label" for="admins">Admins</label>
            </div>
        </div>

        <div
            v-show="state.form.status !== 'success'"
            class="spinner"
        >
            Loading users...
        </div>

        <div
            v-if="state.form.status === 'success'"
        >

            <div class="table-responsive">
                <table class="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>name</th>
                            <th>email</th>
                            <th>role</th>
                            <th>created at</th>
                            <th>updated at</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="user in selectedUsers"
                            v-bind:key="user"
                        >
                            <td>{{ user.id }}</td>
                            <td>{{ user.name }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.role }}</td>
                            <td>{{ formatDate(user.created_at) }}</td>
                            <td>{{ addFiveYears(user.updated_at) }}</td>
                        <!--
                            <td>{{ user.updated_at }}</td>
                        -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {reactive} from 'vue';
    import {onMounted} from 'vue';
    import {computed} from 'vue'
    import {useStore} from 'vuex';

    //  import date format libs
    import { addYears, formatRelative } from 'date-fns'
    import { fr } from 'date-fns/locale'

    export default {
        setup() {
            const store    = useStore();

            const state = reactive({
                form: {
                    status: null,
                    //data: null,
                    //checkedUsers: [0,1],
                },
                checkedUsers: [0,1]
            });

            // call action from Vuex store to update its state
            store.dispatch('users/getAllResources')

            // computed property on Vuex store
            const selectedUsers = computed( () => store.getters['users/getSelectedUsers'] )

            // const from Vuex state from store (not used in this component)
            const users = computed( () => store.state.users.all )

            /*
            examples from Vuex composition api
            ------------------------------------------
            const total = computed(() => store.getters['cart/cartTotalPrice'])
            const checkout = (products) => store.dispatch('cart/checkout', products)
            */

            onMounted(() => {
                state.form.status = 'loading';
                showSelectedUsers()
                state.form.status = 'success';
            });

            function showSelectedUsers () {
                store.dispatch('users/updateCheckedUsers', state.checkedUsers)
            }

            function formatDate(date) {
                // formatage de la date
                return formatRelative(new Date(date), new Date(), { locale: fr })
            }
            function addFiveYears(date) {
                return formatDate(addYears(new Date(date), 5))
            }

            function createUser() {
                console.log("create user")
            }

            // returned vars in template
            return {
                state,
                users,
                selectedUsers,
                showSelectedUsers,
                formatDate,
                addFiveYears,
                createUser
            }
        }
    }
</script>


<style>
/* .bi class included in <h2> */
h2 .bi {
    font-size: 40px;
    color: cornflowerblue;
    padding-right: 10px;
    /* color: grey; */
}

button.btn-primary .bi {
    color: white;
}
</style>
